import React from 'react'
import { Link } from 'react-router-dom'

import { useLogoutQry } from 'mmfintech-backend-api'
import { tr, useDropDownMenu } from 'mmfintech-commons'

import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserDropDownMenu.styled'

export const UserDropDownMenu = ({ UserMenuItems }) => {
  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const { logout } = useLogoutQry()

  const handleLogout = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    hideDropDown()
    void logout()
    return false
  }

  return (
    <DropDownMenuWrapper>
      <DropDownMenuIcon onClick={toggleDropDown}>
        <span className='menu-icon' />
      </DropDownMenuIcon>

      {visible && (
        <DropDownMenuContainer>
          <UserMenuItems onClick={hideDropDown} className='menu-item selector' />
          <div className='menu-footer'>
            <Link to='#' className='menu-item logout' onClick={handleLogout}>
              {tr('PORTAL.HEADER.LOGOUT', 'Log out')}
            </Link>
          </div>
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
